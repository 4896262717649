import type { CapabilityArgs, CapabilityParams, Community, Integration, IntegrationPlatform } from '~/repository/modules'

const [useProvideIntegrationStore, useIntegration] = createInjectionState(
  (id: Integration['id']) => {
    const { $api } = useNuxtApp()

    const loading = ref<boolean>(false)
    const integration = ref<Integration>()
    const get = () => {
      loading.value = true
      $api.integrations.get(id)
        .then((response) => {
          integration.value = response.data
        })
        .finally(() => loading.value = false)
    }

    get()

    return {
      loading,
      integration,
      get,
    }
  },
)

async function useIntegrationPlatforms() {
  const { $api } = useNuxtApp()

  const loading = ref<boolean>(false)
  const platforms = ref<IntegrationPlatform[]>([])

  const list = async () => {
    loading.value = true
    platforms.value = await (await $api.integrations.platforms.list()).data
    loading.value = false
  }

  await list()

  return {
    loading,
    platforms,
    list,
  }
}

async function useCapabilityParams(
  integration: Integration,
  key: string,
) {
  const { $api } = useNuxtApp()

  const loading = ref<boolean>(false)
  const params = ref<CapabilityParams>({})
  const capabilityArgs = ref<CapabilityArgs>({})

  const list = async () => {
    loading.value = true
    params.value = await (await $api
      .integrations
      .getCapabilityParamDefinitions(integration.id, key, capabilityArgs.value)).data

    loading.value = false
  }

  return {
    loading,
    params,
    capabilityArgs,
    list,
  }
}

async function removeIntegration(id: Integration['id']) {
  const { $api } = useNuxtApp()

  return $api.integrations.remove(id)
}

function useIntegrationSyncAll(): {
  loading: Ref<boolean>
  syncAll: (id: Integration['id'], communityId?: Community['id']) => Promise<void>
} {
  const { $api } = useNuxtApp()

  const loading = ref(false)

  const syncAll = async (id: Integration['id'], communityId?: Community['id']) => {
    if (id === undefined || id === '' || id === null)
      throw new Error('Integration ID is required')

    const params = {} as any

    if (communityId)
      params.community_id = communityId

    loading.value = true
    await $api.integrations.syncAll(id, params)
    loading.value = false
  }

  return {
    loading,
    syncAll,
  }
}

function useIntegrationDailySync(): {
  loading: Ref<boolean>
  dailySync: (id: Integration['id']) => Promise<void>
} {
  const { $api } = useNuxtApp()

  const loading = ref(false)

  const dailySync = async (id: Integration['id']) => {
    if (id === undefined || id === '' || id === null)
      throw new Error('Integration ID is required')

    loading.value = true
    await $api.integrations.dailySync(id)
    loading.value = false
  }

  return {
    loading,
    dailySync,
  }
}

function useIntegrationCheckAuth(): {
  loading: Ref<boolean>
  checkAuth: (id: Integration['id']) => Promise<void>
} {
  const { $api } = useNuxtApp()

  const loading = ref(false)

  const checkAuth = async (id: Integration['id']) => {
    if (id === undefined || id === '' || id === null)
      throw new Error('Integration ID is required')

    loading.value = true
    await $api.integrations.checkAuth(id)
    loading.value = false
  }

  return {
    loading,
    checkAuth,
  }
}

export { useProvideIntegrationStore, useIntegration, removeIntegration, useIntegrationPlatforms, useIntegrationSyncAll, useCapabilityParams, useIntegrationDailySync, useIntegrationCheckAuth }
